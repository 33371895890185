import React, { useState, useEffect, useCallback } from 'react'
import {
    View,
    FlatList,
    Text,
    Pressable,
    StyleProp,
    ViewStyle,
    TextStyle,
    PressableProps,
} from 'react-native'
import createStyles from './styles'
import STRINGS from '../../utils/strings'
import { LinearGradientWrapper } from '../../molecules/LinearGradientWrapper'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import COLORS from '../../utils/colors'

export type TabData = Array<
    {
        img?: React.ReactNode
        selectedImg?: React.ReactNode
        name?: string
        id: string
    } & PressableProps
>

export interface BottomTabProps extends PressableProps {
    tabData: TabData
    containerStyle?: StyleProp<ViewStyle>
    activeIndex?: number
    tabStyle?: StyleProp<ViewStyle>
    selectedTabStyle?: StyleProp<ViewStyle>
    textStyle?: StyleProp<TextStyle>
    selectedTextStyle?: StyleProp<TextStyle>
    onIndexChange?: (text: string) => void
    gradientColors?: [string, string]
    isGradient?: boolean
}

export const BottomTab: React.FC<BottomTabProps> = (props) => {
    const {
        tabData,
        containerStyle,
        activeIndex,
        tabStyle,
        selectedTabStyle,
        textStyle,
        selectedTextStyle,
        onIndexChange,
        gradientColors,
        isGradient = false,
        testID,
    } = props

    const { theme } = useTheme()
    const styles = createStyles(theme)
    const [selectedItem, setSelectedItem] = useState<number | null>(
        activeIndex ?? null,
    )

    useEffect(() => {
        setSelectedItem(activeIndex ?? null)
    }, [activeIndex])

    const handlePress = useCallback(
        (index: number) => {
            setSelectedItem(index)
            if (onIndexChange) {
                onIndexChange(String(index))
            }
        },
        [onIndexChange],
    )

    const renderItem = useCallback(
        ({ item, index }) => {
            const isSelected = selectedItem === index

            const { img, selectedImg, name, id, ...pressableProps } = item

            return (
                <Pressable
                    onPress={() => handlePress(index)}
                    testID={STRINGS.BOTTOM_TAB}
                    style={[
                        styles.tab,
                        isSelected ? selectedTabStyle : tabStyle,
                    ]}
                    {...pressableProps}
                >
                    {item.img &&
                        (isSelected ? item.selectedImg || item.img : item.img)}
                    <Text
                        style={[
                            styles.text,
                            isSelected
                                ? [styles.selectedText, selectedTextStyle]
                                : textStyle,
                        ]}
                    >
                        {item.name.slice(0, 12)}
                    </Text>
                </Pressable>
            )
        },
        [
            handlePress,
            selectedItem,
            selectedTabStyle,
            tabStyle,
            textStyle,
            selectedTextStyle,
            styles.tab,
            styles.text,
            styles.selectedText,
        ],
    )

    const defaultGradientColors = [
        COLORS.COLOR_LIGHTBLUE,
        COLORS.COLOR_MEDIUMBLUE,
    ]
    const finalColors =
        gradientColors ||
        (theme && [theme?.colors?.primary, theme?.colors?.secondary]) ||
        defaultGradientColors

    return (
        <View style={containerStyle} testID={testID}>
            <LinearGradientWrapper
                isGradient={isGradient}
                colors={finalColors}
                style={styles.container}
            >
                <FlatList
                    horizontal
                    data={tabData}
                    keyExtractor={(item) => item.id}
                    renderItem={renderItem}
                    contentContainerStyle={styles.contentContainer}
                    CellRendererComponent={({ children }) => children}
                />
            </LinearGradientWrapper>
        </View>
    )
}

import React, { useState, useCallback, useEffect } from 'react';
import {
    Pressable,
    View,
    Image,
    StyleSheet,
    ViewStyle,
    StyleProp,
} from 'react-native';
import { ReactNativeModal } from 'react-native-modal';
import { useWindowDimensions } from 'react-native';
import { useTheme } from '../ThemeProvider/ThemeProvider';
import { Typography } from '../Typography/Typography';
import STRINGS_TESTCASE from '../../utils/stringsTestCase';
import { BuilderTheme } from '../ThemeProvider/themes';
import COLORS from '../../utils/colors';
import closeIconPng from '../../assets/images/close.png'

export interface PopoverProps {
    isVisible?: boolean
    isClosable?: boolean
    onPressCloseIcon?: () => void
    containerStyle?: StyleProp<ViewStyle>
    shadowStyles?: StyleProp<ViewStyle>
    title?: string | React.ReactNode
    titleContainerStyle?: StyleProp<ViewStyle>
    description?: string | React.ReactNode
    children?: React.ReactNode
    closeIcon?: React.ReactNode
    testID?: string
}

export const Popover: React.FC<PopoverProps> = (props: PopoverProps) => {
    const { theme } = useTheme();
    const styles = createStyles(theme);

    const {
        isVisible,
        isClosable = true,
        onPressCloseIcon,
        children,
        containerStyle,
        shadowStyles,
        title,
        titleContainerStyle,
        description,
        closeIcon,
        testID,
    } = props;

    const { height, width } = useWindowDimensions();
    const [isModalVisible, setModalVisible] = useState(isVisible || false);
    const shouldShowHeader = title || isClosable;

    useEffect(() => {
        setModalVisible(isVisible);
    }, [isVisible]);

    const gapView = <View style={styles.gapView}></View>;

    const _title =
        typeof title === 'string' ? (
            <>
                <Typography variant="2xl" style={styles.txtTitle}>
                    {title}
                </Typography>
                {gapView}
            </>
        ) : (
            <>
                {title}
                {gapView}
            </>
        );

    const _description =
        typeof description === 'string' ? (
            <Typography variant="lg" style={styles.txtDescription}>
                {description}
            </Typography>
        ) : (
            description
        );

    const _closeIcon = closeIcon ? (
        closeIcon
    ) : (
        <Image source={closeIconPng} style={styles.crossIcon} />
    )

    const _onPressCloseIcon = useCallback(() => {
        if (onPressCloseIcon) {
            onPressCloseIcon();
        } else {
            setModalVisible(!isModalVisible);
        }
    }, [onPressCloseIcon, isModalVisible, setModalVisible]);

    return (
        <ReactNativeModal
            deviceHeight={height}
            deviceWidth={width}
            isVisible={isModalVisible}
            style={[styles.modal, shadowStyles]}
            onBackdropPress={isClosable ? _onPressCloseIcon : null}
            testID={testID}
        >
            <View style={[styles.innerContainer, containerStyle]}>
                {shouldShowHeader && (
                    <View style={[styles.titleContainer, titleContainerStyle]}>
                        {title ? _title : null}
                        {isClosable && (
                            <Pressable
                                onPress={_onPressCloseIcon}
                                testID={STRINGS_TESTCASE.POPOVER_INPUT_COMPONENT_ICON}
                                style={closeIcon ? null : styles.iconContainer}
                            >
                                {_closeIcon}
                            </Pressable>
                        )}
                    </View>
                )}
                {description ? _description : null}
                {children ? children : null}
            </View>
        </ReactNativeModal>
    );
};

const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    modal: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainContainer: {
        flex: 1,
        borderTopRightRadius: theme?.spacing?.borderRadius ?? 24,
    },
    innerContainer: {
        borderTopLeftRadius: theme?.spacing?.borderRadius ?? 8,
        borderTopRightRadius: theme?.spacing?.borderRadius ?? 32,
        borderBottomLeftRadius: theme?.spacing?.borderRadius ?? 8,
        borderBottomRightRadius: theme?.spacing?.borderRadius ?? 8,
        backgroundColor: theme?.colors?.background ?? '#FFFFFF',
        paddingHorizontal: theme?.spacing?.padding ?? 30,
        paddingVertical: theme?.spacing?.padding ?? 25,
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        borderBottomColor: theme?.colors?.border ?? COLORS.COLOR_GREY,
        borderBottomWidth: theme?.spacing?.borderWidth ?? 1,
        borderStyle: 'solid',
    },
    gapView: {
        width: theme?.spacing?.margin ?? 12,
    },
    horizontalLine: {
        height: 1,
        backgroundColor: theme?.colors?.border ?? COLORS.COLOR_GREY,
        marginTop: theme?.spacing?.margin ?? 15,
        marginHorizontal: -(theme?.spacing?.padding ?? 30),
    },
    iconContainer: {
        alignSelf: 'flex-start',
        marginTop: theme?.spacing?.margin ?? 8,
    },
    crossIcon: {
        width: theme?.icons?.size ?? 14,
        height: theme?.icons?.size ?? 14,
    },
    txtTitle: {
        color: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
        fontWeight: theme?.typography?.fontWeight?.bold ?? '700',
        flexShrink: 1,
        marginTop: 0,
        marginBottom: 0,
    },
    txtDescription: {
        marginTop: theme?.spacing?.margin ?? 15,
        color: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
    },
    txtFooter: {
        fontSize: theme?.typography?.size?.base?.fontSize ?? 16,
        marginTop: theme?.spacing?.margin ?? 40,
        color: theme?.colors?.primary ?? COLORS.COLOR_MEDIUMBLUE,
        alignSelf: 'center',
        fontWeight: theme?.typography?.fontWeight?.bold ?? '700',
        marginHorizontal: theme?.spacing?.margin ?? 15,
    },
});

